import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Page from '../components/Page'
import useNavMenuData from '../containers/useNavMenuData'
import useFooterMenuData from "../containers/useFooterMenuData"
import useSocialMenuData from '../containers/useSocialMenuData'

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    page: sanityPage(id: { eq: $id }) {
      id
      title
      _createdAt
      _updatedAt
      slug {
        current
      }
      intro: _rawIntroduction
      introduction {
        sanityChildren {
          marks
          text
        }
      }
    }
  }
`

const PageTemplate = props => {
  const { data, errors } = props
  const page = data && data.page
  const navMenuData = useNavMenuData()
  const footerMenuData = useFooterMenuData()
  const socialMenuData = useSocialMenuData()

  return (
    <>
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {page && (
        <Page
          intro={page.intro}
          siteTitle={data.site.title}
          navMenu={navMenuData}
          footerMenu={footerMenuData}
          socialMenu={socialMenuData}
        />
      )}
    </>
  )
}

export default PageTemplate
