import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/fp/get'

const query = graphql`
  query NavMenuQuery {
    menus: allSanitySiteMenus {
      edges {
        node {
          navMenu {
            link {
              ... on SanityExternalLink {
                _key
                _type
                externalUrl
                linkDetails {
                  title
                  browserTarget
                  linkDisplayType
                  icon {
                    asset {
                      url
                    }
                    altText
                  }
                }
              }
              ... on SanityPageReference {
                _key
                _type
                linkDetails {
                  title
                  browserTarget
                  linkDisplayType
                  icon {
                    altText
                    asset {
                      url
                    }
                  }
                }
                page {
                  slug {
                    current
                  }
                }
              }
              ... on SanityActionButton {
                _key
                _type
                title
                behaviors 
                icon {
                    altText
                    asset {
                      url
                    }
                  }

              }
            }
          }
        }
      }
    }
  }
`

function useNavMenuData() {
  const data = useStaticQuery(query)
  return get('menus.edges[0].node.navMenu.link', data)
}

export default useNavMenuData
