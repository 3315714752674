import React from 'react'
import styled from '@emotion/styled'

const H1 = styled.h1``
const H2 = styled.h2``
const H3 = styled.h3``
const H4 = styled.h4``
const H5 = styled.h5``
const H6 = styled.h6``

const P = styled.p``

const Em = styled.em``
const Span = styled.span``
const Strong = styled.strong``
const Link = styled.a``
const Underline = styled.span`
  text-decoration: underline;
`
const Li = styled.li``

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  Em,
  Span,
  Strong,
  Link,
  Underline,
  Li
}