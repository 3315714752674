import React, { useState, useEffect } from 'react'
import Header from './header'
import HeroSection from './Hero'
import CopySection from './Copy'
import VideoBackground from './VideoBackground'
import HeroBackgroundMobile from './hero-background-mobile.jpg'
import video from './VideoBackground/video-background-only.mp4'
import styled from '@emotion/styled'
import FooterSection from './Footer'
import CFLogo from './CF-Logo.png'
import Media from 'react-media'
import * as primitives from './page-primitives'
import { Li } from './page-primitives'

import '../styles/layout.css'
import styles from './layout.module.css'
const safeElements = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'br',
  'em',
  'i',
  'mark',
  'q',
  'span',
  'strong',
  'u',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr'
]

const HEADER_HEIGHT = 300

function PortableText({ data, markDefs }) {

  if (data.marks && data.marks.length) {
    // create element
    const markValues = (data.marks || []).map(mark => {
      const matchDef = markDefs.find(defs => defs._key === mark)
      return typeof matchDef === 'undefined' ? mark : matchDef
    })

    return markValues.reverse().reduce((acc, value) => {
      if (typeof value === 'string') {
        const Component = primitives[value.charAt(0).toUpperCase() + value.slice(1)] || value
        return <Component>{acc}</Component>
      } else {
        const { _key, _type, ...props } = value
        const Component = primitives[value._type.charAt(0).toUpperCase() + value._type.slice(1)] || value._type
        return <Component {...props}>{acc}</Component>
      }
    }, data.text)

  }
  if (safeElements.indexOf(data._type) !== -1) {
    const Component = data._type
    return <Component>{data.text}</Component>
  }
  return null
}

function Page({ intro, onHideNav, onShowNav, showNav, siteTitle, navMenu, footerMenu, socialMenu }) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return null

  return (
    <Root>
      <StyledHero height={[HEADER_HEIGHT, 150]}>
        <Media queries={{
          desktop: "(min-width: 768px)",
        }}>
          {matches => matches.desktop && <VideoBackground src={video} style={{ height: HEADER_HEIGHT }} />}
        </Media>
        <Header
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          navMenu={navMenu}
        />
        <Logo>
          <img src={CFLogo} alt="Cosmic Forces" width="563" height="204" />
        </Logo>
      </StyledHero>
      <Container>
        {intro && intro.map((block, index) => block._type === 'table' ? (
          <Table block={block} key={index} />
        ) : (
            <Block style={block.style} key={index} block={block}>
              {block.children &&
                block.children.map(child => <PortableText key={child._key} data={child} markDefs={block.markDefs} />)}
            </Block>
          ))}
      </Container>
      <FooterSection footerMenu={footerMenu} socialMenu={socialMenu} />
    </Root>
  )
}

function Block({ style, children, block }) {
  if (block && block.listItem && block.listItem === 'bullet') {
    return <Li>{children}</Li>
  }
  if (safeElements.indexOf(style) !== -1) {
    const DefaultComponent = style
    let props = {}
    // Add id props if it's a heading
    if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(style) !== -1) {
      const headingId = block.children.map(child => child.text).join(' ').split(' ').join('_')
      props = { ...props, id: headingId }
    }
    return <DefaultComponent {...props}>{children}</DefaultComponent>
  }
  return <p>{children}</p>
}


function Table({ block }) {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <Thead>
        <tr>
          {block.rowHeaders.map((header, index) =>
            <Th key={index}>{header}</Th>
          )}
        </tr>
      </Thead>
      <tbody>
        {(block.rows || []).map(row =>
          <tr key={row._key}>
            {(row.columns || []).map(column =>
              <Td key={column._key}>
                {(column.cells || []).map(item =>
                  <Block style={block.style} key={block._key}>
                    {item.children &&
                      item.children.map(child => {
                        return <PortableText
                          key={child._key}
                          data={child}
                          markDefs={item.markDefs}
                        />
                      })}
                  </Block>
                )}
              </Td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  )
}

const Thead = styled.thead`
  font-size: 0.8em;
  text-align: left;
`


const Td = styled.td`
  border: 1px solid black;
  padding: 0.5rem;
  vertical-align: top;
`
const Th = styled.th`
  border: 1px solid black;
  padding: 0.5rem;
  vertical-align: bottom;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

// const P = styled.p`
//   /* word-break: break-word; */
// `

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 4rem;
  font-size: 1.8rem;
  flex-grow: 1;
`

const StyledHero = styled(HeroSection)`
  clip-path: none;
  background: url(${HeroBackgroundMobile}) center center no-repeat;
  background-size: cover;
  flex: 0 0 ${props => props.height && props.height.length && props.height[1]}px;
  padding: 0;

  @media (min-width: 768px) {
    background: none;
    flex: 0 0 ${props => props.height && props.height.length && props.height[0]}px;
  }
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  
  & img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

export default Page
